import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import Layout from "../components/layout"
import YellowDots from "../../static/images/explore-yellow-dots.svg"
import PinkLine from "../../static/images/explore-pink-line.svg"
import YellowDot from "../../static/images/explore-yellow-dot.svg"
import BlueDots from "../../static/images/explore-blue-dots.svg"


const SignUp = () => (
  <Layout>
    <Helmet>
      <title>Sign up | IBDmate</title>
    </Helmet>
    <div className="layout-sign-up">
      <div className="purple-bg">
        <div className="content">
          <h1>Sign up</h1>
          <p>IBDmate is a free online resource anyone can use</p>
        </div>
        <div className="vectors-left">
          <img src={YellowDots} id="yellow-dots" alt="" />
          <img src={PinkLine} id="pink-line" alt="" />
        </div>
        <div className="vectors-right">
          <img src={YellowDot} id="yellow-dot" alt="" />
          <img src={BlueDots} id="blue-dots" alt="" />
        </div>
      </div>
      <div className="white-bg">
        <div className="wrapper">
          <h2>Do you have a code?</h2>
          <div className="card">
            <h3>Yes, I have a code</h3>
            <p>I have been give a code by my IBD doctor or nurse at Addenbrooke's Hospital Cambridge</p>
            <Link to="https://ibdmate.app" className="btn btn-purple" target="_blank">Enter code</Link>
          </div>
          <div className="card">
            <h3>No, I don't have a code</h3>
            <Link to="https://ibdmate.app" className="btn btn-purple" target="_blank">Sign up</Link>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default SignUp
